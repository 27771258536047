.footer-wrap {
  background-color: #000000;
  color: rgba(255, 255, 255);
  display: grid;
  padding-top: 41px;
  padding-bottom: 37px;
}
.footer-wrap a {
  color: rgba(255, 255, 255);
  font-size: 16px;
  line-height: 18.2px;
}
.footer-wrap a:hover {
  text-decoration: none;
  color: #afca0b;
}
.col-first {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 12px !important;
  padding-left: 3px;
}
/* .col-first img {
  width: 160px;
} */
img.logo-footer.img-fluid {
  position: relative;
  top: 18px;
  width: 185px;
  /* height: 27.5px; */
}
span.copyright-wrapper {
  position: relative;
  top: -5px;
}
.col-second img {
  width: 72px;
}
.col-second {
  position: relative;

  top: 5px;
  padding-top: 10px;
  padding-left: 10px;
}
.main-info-footer {
  border-right: 1px solid #82a01e;
  width: 97.8%;
  padding: 6px 0;
}
.holder-col-last {
  margin: 24px 0 0 0;
}
.holder-col-link {
  margin: 6px 0;
}

.holder-col-link:nth-child(6) {
  margin: 14px 0;
}

.col-third {
  position: relative;
  top: 18px;
  height: 80%;
  padding-left: 32px;
}
.line-horizontal-footer {
  width: 30%;
  height: 1px;
  background-color: #afca0b;
  margin-bottom: 63px;
}
