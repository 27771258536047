.dropdown-select-wrap {
  width: 100%;
  position: relative;
}
.ltq-select__control.css-1xqf96c-control:focus {
  border-color: #79a254;
}

.ltq-select__control.css-1xqf96c-control:hover {
  border-color: #79a254;
}
.css-g2qsin-control {
  border-color: #79a254 !important;
  outline: none !important;
  box-shadow: none !important;
}
.css-g2qsin-control:focus {
  border-color: #79a254 !important;
  outline: none !important;
  box-shadow: none !important;
}

.css-1v1gakp-control {
  border-color: #79a254 !important;
  outline: none !important;
  box-shadow: none !important;
}

.css-1097pkj-option {
  color: #000 !important;
}
.css-1097pkj-option:hover {
  color: #79a254 !important;
}
.css-1097pkj-option:active {
  color: #79a254 !important;
}

.label-dropdown-select {
  pointer-events: none;
  position: absolute;
  top: 17px;
  color: #999999;
  left: 10px;
  transform-origin: 0 0;
  transition: transform 0.3s ease-in-out;
}
.transform-label {
  transform: translateY(-10px) scale(0.7);
  margin-bottom: 10px;
}
.ltq-select__single-value.css-qc6sy-singleValue {
  margin-top: 12px;
  transition: transform 0.3s ease-in-out;
}
span.ltq-select__indicator-separator.css-1okebmr-indicatorSeparator {
  display: none;
}
.ltq-select__indicator.ltq-select__dropdown-indicator.css-tlfecz-indicatorContainer {
  display: none;
}
.checkmark {
  position: absolute;
  pointer-events: none;
  right: 15px;
  top: 15px;
}
.ltq-select__indicator.ltq-select__dropdown-indicator.css-1gtu0rj-indicatorContainer {
  display: none;
}
