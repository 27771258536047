.h-100vh {
    height: 100vh !important;
}

.clock {
    font-size: 100%;
    width: 12em;
    height: 12em;
    background: transparent;
    border: 3px solid #666;
    border-radius: 50%;
    position: relative;   
 }
.clock:after, .clock:before{
    position: absolute;
    content: '';
    background: #999;
    border-radius: 50%;
}
.clock:after{
    width: 5.4em;
    height: .15em;
    top: 5.8em;
    left: 5.8em;
    transform-origin: .1em .1em;
    animation: minutehand 2.55s linear infinite;
}

.clock:before{
    width: 4.3em;
    height: .2em;
    top: 5.8em;
    left: 5.8em;
    transform-origin: .1em .1em;
    animation: hourhand 10s linear infinite;
}

@keyframes minutehand{
    0% {transform:rotate(0deg)}
    100% {transform:rotate(360deg)}
}
@keyframes hourhand{
    0% {transform:rotate(0deg)}
    100% {transform:rotate(360deg)}
}