.footer {
  padding: 1.1rem;
  background-color: rgb(255, 255, 255);
  opacity: 0.85;
  width: 100%;
  justify-content: flex-end;
  flex-shrink: 0;
}
.w3-container {
  width: 100%;
}

a {
  color: rgb(0, 0, 0);
}

a:hover {
  color: rgb(1, 117, 20);
}

.dbadge-step {
  font-size: 25px;
  width: 34px;
  height: 34px;
  line-height: 34px;
  margin-right: 10px;
}

.mbadge-step {
  font-size: 18px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  margin-right: 10px;
}

.margin-step {
  margin-top: 13px;
}
.navbar.bg-light {
  flex-shrink: 0;
}
