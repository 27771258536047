.layout-root {
  /* background-color: #e5e5e5; */
}

.layout-title {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 14pt;
  color: #1C1F26 !important;
  text-transform: uppercase;
}
.layout-content {
  padding: 24px;
}
.layout-footer {
  margin-top: 24px;
}

.layout-button {
  min-width: 240px !important;
  height: 64px !important;
}

.layout-button:focus {
  box-shadow: 0 0 0 0 !important;
}

.layout-button-mobile {
  min-width: 200px !important;
  height: 42px !important;
}

.layout-button-mobile:focus {
  box-shadow: 0 0 0 0 !important;
}

.layout-footer-title {
  display: inline-block;
}

.MuiStepIcon-root.MuiStepIcon-active, .MuiStepIcon-root.MuiStepIcon-completed {
  color: rgb(88, 153, 88) !important;
}

.MuiStepper-root {
  display: flex;
  padding: 18px !important;
  padding-bottom: 8px !important;
}

.MuiStepConnector-line {
  display: block;
  border-color: #ffffff !important;
}