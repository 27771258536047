.sticky-header {
  width: 100%;
  background-color: #fff;
  height: 79.7px;
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); */
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: space-between;
  padding-top: 21px;
  padding-bottom: 21px;
  padding-left: 47px;
  padding-right: 61px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif;
}
button.font-weight-bold.language-modal-menu.dropdown-toggle.btn.btn-primary:hover {
  color: #000 !important;
  background-color: transparent !important;
  border: none !important;
}
.dropdown-item-modal {
  transform: translate3d(-22px, 26px, 0px) !important;
}
.sticky-header a {
  color: #000;
}
.sticky-header a:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
img.logo-header {
  width: 175px;
  max-width: 100%;
}
.sticky-header-first-wrap {
  display: flex;
  justify-content: start;
  align-items: center;
}
.sticky-header-first-wrap img {
  height: auto;
}
img.close-icon.img-fluid {
  max-width: 100%;
  width: 40px;
}
.logo-and-link-wrapper {
  font-family: "Montserrat", sans-serif;
  position: absolute;
  top: 100px;
}
.main-body-content-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.item-logo-modal {
  margin-bottom: 10px;
}
.item-logo-modal-homepage {
  margin-bottom: 10px;
}
.item-logo-modal img {
  width: 190px;
  height: auto !important;
  margin-bottom: 10px;
}
.item-logo-modal-about-us {
  margin-bottom: 10px;
}

.dropdown-modal-wrapper {
  position: absolute;
  bottom: 153px;
}

.language-modal-menu {
  font-family: "Montserrat", sans-serif;
  color: #000 !important;
  background-color: unset !important;
  outline: none !important;
  box-shadow: none !important;
  border-color: transparent !important;
  padding: 0;
}
.language-modal-menu:focus {
  background-color: transparent;
  color: #000;
}
.language-modal-menu::after {
  content: "";
  vertical-align: middle;
  display: inline-block !important;
  border: 0.35em solid transparent;
  border-top: 0.5em solid;
  position: absolute;
  right: -25px;
  top: calc(50% - 0.275em);
}
.linked-modal-wrapper {
  position: absolute;
  bottom: 45px;
}
img.linked-icon.img-fluid {
  max-width: 100%;
  width: 89px;
}
.modal-body-custom.modal-body {
  padding-left: 8px;
}
.close-icon-wrapper {
  display: flex;
  justify-content: end;
}
.logo-wrapper-v2 {
  margin-right: 21px;
  height: 28px;
}
.link-wrapper {
  padding-left: 2px;
}
.sticky-header-second-wrap {
  display: flex;
  align-items: center;
}
.menu-link {
  font-family: "Roboto", Sans-serif !important;
  font-size: 16px;
  margin: 0 15px;
}
.active {
  color: #79a254;
}

.dropdown-wrapper:hover {
  color: #79a254;
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .dropdown-wrapper {
    display: flex;
    align-items: center;
    padding-top: 1px;
  }
}

@media only screen and (max-width: 767px) {
  .mobil-header {
    display: flex;
    justify-content: space-between;
    padding: 18px 10px 16px 18px;
    align-items: center;
    background-color: #fff;
  }
  .dropdown-menu.show {
    inset: -216px auto auto 0px !important;
    background-color: transparent !important;
    outline: none !important;
  }
  .dropdown-menu:focus-visible {
    outline: none !important;
  }
  .dropdown-menu:visited {
    outline: none !important;
  }
  .dropdown-item {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
      Helvetica Neue, Arial, Noto Sans, sans-serif;
    padding: 0.35rem 1.5rem !important;
  }
}

/* Landscape */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1365px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
}

@media only screen and (max-width: 991px) {
  .sticky-header {
    padding-left: 20px;
  }
}
