.w-80 {
    width: 80% !important;
}

.w-55 {
    width: 55% !important;
}

.sub-field {
    width: 230px !important;
    margin-right: 15px !important;
}

.btn-secondary {
    color: black;
    background-color: rgb(229, 229, 229);
    border-color: black;
}

.btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active {
    color: #fff;
    background-color: #79A254 !important;
}