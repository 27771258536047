.dropdown-sticky-menu.dropdown:active {
  background-color: transparent;
  color: #000;
  border-color: transparent;
  font-weight: bold;
  outline: none;
}

.dropdown-sticky-menu.dropdown:hover {
  background-color: transparent;
  color: #000;
  border-color: transparent;
  font-weight: bold;
  outline: none;
}
.dropdown-menu {
  min-width: 143px;
  border: none;
}
.dropdown-item {
  font-weight: 700;
  padding: 0.75rem 1.5rem;
}
a.dropdown-item:hover {
  background-color: transparent;
  color: #82a01e;
}
.dropdown-sticky-menu.dropdown:focus-visible {
  border: none !important;
  outline: none;
}

.dropdown-sticky-menu.dropdown
  button
  .btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #000;
  background-color: transparent;
  border-color: transparent;
}

.dropdown-sticky-menu.dropdown
  button
  .btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.dropdown-sticky-menu {
  display: flex;
  justify-content: end;
}

.dropdown-sticky-menu button {
  padding: 0;
}
/* Small devices (tablets, 768px and smaller) */
@media only screen and (max-width: 768px) {
  .dropdown-sticky-menu.dropdown button {
    color: transparent !important;
  }
  /* .dropdown-toggle::after {
    display: none !important;
  } */
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-color: transparent;
  color: #000;
  border-color: transparent;
  outline: none;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
}
.dropdown-sticky-menu.dropdown button {
  background-color: transparent !important;
  color: #000 !important;
  border: none !important;
  font-size: 16px;
  box-shadow: none !important;
  width: 98%;
}

.dropdown-toggle::after {
  content: "";
  vertical-align: middle;
  display: inline-block;
  border: 0.35em solid transparent;
  border-top: 0.5em solid;
  position: absolute;
  right: -28px;
  top: calc(50% - 0.215em);
}
.modal-dialog-custom-mobile {
  position: fixed;
  z-index: 9999;
  background-color: #fefefe;
  width: 100%;
  height: 100%;
  padding: 10px;
  -webkit-animation-name: slideFromRight;
  -webkit-animation-duration: 0.4s;
  animation-name: slideFromRight;
  animation-duration: 0.4s;
}
.modal-mobile {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  left: 0;
  top: 0;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  -webkit-animation-name: fadeIn; /* Fade in the background */
  -webkit-animation-duration: 0.5s;
  animation-name: fadeIn;
  animation-duration: 0.5s;
}

@keyframes slideFromRight {
  from {
    right: -300px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes sliderOut {
  from {
    opacity: 1;
    right: 0;
  }
  to {
    right: -300px;
    opacity: 0;
  }
}

@media screen and (max-width: 767px) {
  .dropdown-toggle::after {
    display: none;
    right: -104px;
    top: calc(50% - 0.175em);
  }
  .dropdown-sticky-menu.dropdown button {
    padding: 0;
  }
}
