.form-control {
  color: #1c1f26 !important;
  outline: none !important;
  outline-offset: none !important;
  height: 3.5em;
}

.form-control:focus {
  box-shadow: none;
  outline: none !important;
  outline-offset: none !important;
  border-color: #afca0b;
}

.input-shadow,
.css-15sk751-control,
.css-1rrfryo-control {
  background-color: white !important;
  border: 1px solid #afca0b !important;
}

.input-shadow:focus,
.css-15sk751-control:focus,
.css-1rrfryo-control:focus {
  box-shadow: none !important;
}

.css-1rrfryo-control,
.css-1rrfryo-control:hover,
.css-1rrfryo-control:active,
.css-1rrfryo-control:focus {
  border-color: #afca0b !important;
  box-shadow: 0 0 0 1px #afca0b !important;
}

.custom-control-input:checked ~ .custom-control-label::before,
.btn-primary,
.btn-primary.disabled,
.btn-primary:disabled,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  color: #fff !important;
  background-color: #afca0b !important;
  border-color: #afca0b !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #afca0b !important;
  color: #fff;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #79a254 !important;
}

.pageTitle {
  font-weight: 400;
  font-style: normal;
  font-size: 26pt;
  color: #007bff !important;
}

.vielenDankWrapper {
  background-color: #ffffff;
}

.vielenDankTitle {
  color: #2f80ed;
  font-weight: 400;
  font-size: 18px;
}

.titleBorder {
  border-bottom: 0.5px solid #e0e0e0;
}

.ul {
  padding-left: 1em;
  padding-top: 1em;
  list-style: none;
}

.ul li::before {
  content: "-";
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.item {
  font-style: normal;
  font-weight: normal;
  font-size: 18pt;
  line-height: 20px;
  margin-bottom: 20px;
}

.content-height {
  min-height: 20em;
  max-height: 20em;
}

.s-16 {
  font-size: 16pt;
}

.s-14 {
  font-size: 14pt;
}

.forward-img {
  height: 100%;
  width: 100%;
  /* object-fit: contain; */
}

.forward-button {
  border-radius: 8px;
}

.content-label {
  height: 105px;
  display: flex;
  align-items: flex-end;
  position: relative;
}

.min-height-0 {
  min-height: 0 !important;
}

.form-check-inline {
  align-items: flex-start;
  display: flex !important;
}

.form-check-label {
  margin-bottom: 0 !important;
  flex: 1 !important;
}

.form-check-inline .form-check-input {
  margin-top: 0.4rem !important;
}