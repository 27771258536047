.cta2 {
  background-color: #ffffff;
}
.cta-sticky2 {
  background-color: #ffffff;
  display: grid;
  padding: 20px 0;
  height: 80px;
  max-width: 1366px;
  width: 100%;
}
/* .cta-sticky {
  padding: 20px 15px 15px 15px;
  width: 100%;
  background-color: #fff;
} */
.space-sticky-cta.for-line {
  height: 100%;
  border-right: 1px solid #79a254;
}
.info-value {
  font-weight: 700;
}
.main-info-item-wrap {
  margin-bottom: 16px;
}

.info-key {
  font-size: 16px;
  line-height: 130%;
  text-transform: capitalize;
  color: #000;
  font-weight: 400;
}
.title-modal-cta-wrapper {
  margin-bottom: 24px;
}
.big-title-short-info {
  font-family: Montserrat;
  font-size: 18px;
  letter-spacing: 0.1px;
  margin-bottom: 4px;
}
.question-text-wrapper {
  font-family: Montserrat;
  font-size: 18px;
  letter-spacing: 0.1px;
}
.for-short-info h2 {
  font-size: 32px;
  font-family: Montserrat;
  line-height: 38.4px;
  letter-spacing: 0.1px;
}
.col-third-modal-cta.for-main-info {
  margin-top: 62px;
}

.border-short {
  border: 1.5px solid #82a05d;
  padding: 16px 24px;
  width: 100%;
  border-radius: 10px;
  line-height: 9px;
}
.modal-sticky-wrap {
  display: grid;
  padding: 40px 0 32px 0;
  max-width: 1366px;
  width: 100%;
}
.btn-direction {
  border: none;
  color: rgb(130, 160, 93);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgb(229, 229, 229);
  display: flex;
  justify-content: center;
  align-items: center;
}
.short-info-wrap {
  line-height: 9px;
}

.main-info-wrap-flow3 {
  padding-left: 3em;
  padding-top: 51px;
}
.flow3-btn button {
  font-weight: 600;
  padding: 15px;
}
.flow3-btn {
  display: flex;
  flex-direction: column;
}
.flow3-btn button {
  min-width: 213px !important;
}

.short-info-wrapper {
  line-height: 9px;
  margin-bottom: 24px;
}

.ltq-config-modal {
  margin-top: 16px;
}

.btn-direction:hover {
  background-color: rgb(243, 246, 239);
}
.btn-direction.arrow-down {
  color: rgb(130, 160, 93);
}

svg.arrow-down {
  transform: rotate(180deg);
}
svg.arrow-down path {
  fill: currentcolor;
}
.MuiSvgIcon-root {
  color: rgb(130, 160, 93);
  font-size: 28px !important;
}
/* CTA STICKY */
.cta-item-wrap {
  display: flex;
  padding-left: 40px;
}
.second-item-wrap {
  border-left: 2px solid #82a05d;
}

.mb-3.modal-sticky-btn button {
  width: 230px;
}

.modal.show .modal-dialog {
  transform: none;
  position: absolute;
  bottom: -50px;
  max-width: 100%;
  /* z-index: 9999; */
  margin-bottom: 0;
  transition: all 2s;
}
.modal .modal-dialog {
  position: absolute;
  bottom: -50px;
  max-width: 100%;
  /* z-index: 9999; */
  margin-bottom: 0;
  transition: all 2s;
}
.modal-backdrop {
  position: fixed;
  bottom: 0;
  left: 0;
  /* z-index: 1040; */
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal.fade .modal-dialog {
  transition: transform 2s ease-out;
  transform: translate(0, -50px);
  width: 100%;
}
.short-sub-title {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  margin-left: 5px;
}
.modal-dialog-custom2 {
  position: fixed;
  z-index: 9999;
  bottom: 0;
  background-color: #fefefe;
  width: 100%;
  -webkit-animation-name: slideIn;
  -webkit-animation-duration: 0.4s;
  animation-name: slideIn;
  animation-duration: 0.4s;
}
.modal2 {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  -webkit-animation-name: fadeIn; /* Fade in the background */
  -webkit-animation-duration: 0.5s;
  animation-name: fadeIn;
  animation-duration: 0.5s;
}

@keyframes slideIn {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .modal-sticky-wrap {
    padding: 40px 15px;
  }
  .info-key {
    font-size: 14px;
  }
  .info-value {
    font-size: 14px;
  }
}
@media only screen and (max-width: 767px) and (min-width: 376px) {
  .info-item-wrapper {
    flex-direction: column-reverse;
  }
  .border-short {
    margin-top: 20px;
  }
  .question-text-wrapper {
    font-size: 18px;
    margin-top: 20px;
  }
  .flow3-btn button {
    min-width: 195px !important ;
  }
  .content-mobile-wrapper {
    margin-top: 0%;
  }
  .main-info-item-wrap {
    display: grid;
    grid-template-columns: 185px 150px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .col-second-modal-cta.for-short-info {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 375px) {
  .cta-main-info-wrapper {
    margin-bottom: 0;
  }
  .modal-sticky-wrap {
    padding-top: 0;
  }
  .info-item-wrapper {
    flex-direction: column-reverse;
  }
  .content-mobile-wrapper {
    margin-top: 0%;
  }
  .main-info-item-wrap {
    display: grid;
    grid-template-columns: 185px 150px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .question-text-wrapper {
    font-size: 18px;
  }
  .flow3-btn button {
    min-width: 195px !important ;
  }
}

@media only screen and (max-width: 767px) {
  .space-sticky-cta.col-sixth {
    display: none;
  }
  .space-sticky-cta.for-line {
    display: none;
  }
  .cta-sticky2 {
    grid-template-columns: calc(100vw / 60 * 3.2) calc(100vw / 60 * 9) 0px auto !important;
  }
  .visiable-portrait {
    display: none;
  }
  .info-key {
    font-size: 14px;
  }
  .info-value {
    font-size: 14px;
  }
  .col-third-modal-cta.for-main-info {
    display: none;
  }
  .modal-sticky-wrap {
    display: flex;
    flex-direction: column;
    padding: 25px;
  }
  .ltq-config {
    margin-top: 10px;
  }
  .flow3-btn {
    flex-direction: column;
  }
  .btn-wrapper {
    margin-left: 0;
  }
  .modal-dialog-custom2 {
    height: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .visiable-mobile {
    display: none;
  }
  .visiable-portrait {
    display: none;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .space-sticky-cta.for-line {
    display: none;
  }
  .col-third-sticky-cta {
    display: none;
  }
  .short-info-wrapper-mobile {
    margin-top: 24px;
  }
  .question-text-wrapper {
    margin-top: 32px;
  }
  .space-modal-cta {
    display: none;
  }
  .btn-modal-wrapper {
    margin-bottom: 40px !important;
  }
  .modal-sticky-wrap {
    display: block;
    padding-left: 38px;
    padding-top: 40px;
  }
  .visiable-portrait {
    display: grid;
    grid-template-columns: calc(100vw / 60 * 25) calc(100vw / 60 * 3) auto calc(
        100vw / 60 * 2
      );
  }
  .lanscape-visiavle {
    display: none;
  }
  button.btn-wrap {
    font-size: 16px !important;
  }
  .right-disclaimer {
    margin-top: 41px !important;
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 1192px) {
  .space-sticky-cta.col-sixth {
    display: none;
  }
  .col-third-sticky-cta {
    display: none;
  }
  .space-sticky-cta.for-line {
    display: none;
  }
}
