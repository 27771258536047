#breadcrumb {
  display: flex;
  padding: 0;
  margin: 0;
  height: 56px;
  overflow-x: auto;
  overflow-y: hidden;
  padding-right: 24px;
}
#breadcrumb > li {
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#breadcrumb > li:last-child {
  margin: 0;
}

@media only screen and (min-width: 993px) {
  #breadcrumb > * {
    flex: 1;
  }
}
@media only screen and (max-width: 992px) {
  #breadcrumb {
    display: -webkit-inline-box;
  }
}
