.logo-wrapper {
  z-index: 100;
}

.logo-size {
  height: 50px;
  width: 186px;
}

.logo-size-small {
  height: 28px;
  width: 110px;
}

.logo-image {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.green {
  color: #79a254;
}
