
.customSlider {
  height: 1em;
  flex-grow: 1;
}

.sliderThumb {
  width: 1.5em;
  height: 1.5em;
  border-radius: 1em;
  border: 1px solid #AFCA0B;
  background-color: #AFCA0B;
  outline: none;
  display: flex;
}

.innerCircle {
  width: 0.5em;
  height: 0.5em;
  border-radius: 0.25em;
  background-color: #AFCA0B;
  position: relative;
  margin: auto;
}

.custom-slider-input, .final-value-input {
  background-color: white !important;
  border: 1px solid lightgrey !important;
  box-shadow: 1px 1px 1px 1px lightgrey;
  text-align: right;
  height: 3em;
}

.example-mark {
  font-size: smaller;
  top: 1.7em;
  right: 2em;
}

.sliderThumb:active {
  outline: none;
}

.sliderTrack {
  background: #79A254;
  border-radius: 0.5em;
  height: 0.2em;
  top: 0.6em;
}
.sliderTrack.sliderTrack-0 {
  /* background: #00CCFF; */
}
