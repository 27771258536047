.toggle {
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  padding: 4px;
  background-color: #e8e8e8;
  border-radius: 8px;
  width: 100%;
  height: 44px;
}
.toggle input {
  width: 0;
  height: 0;
  position: absolute;
  left: -9999px;
}
.toggle input + label {
  padding: 5px;
  font-weight: 400;
  margin-right: 2px;
  border-radius: 4px;
  padding: 8px;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  background-color: #e8e8e8;
  color: #757575;
  font-size: 1rem;
  line-height: 140%;
  text-align: center;
  transition: border-color 0.15s ease-out, color 0.25s ease-out,
    background-color 0.15s ease-out, box-shadow 0.15s ease-out;
}
.toggle input + label:first-of-type {
  border-radius: 4px;
  width: 100%;
  margin-bottom: 0;
}
.toggle input + label:last-of-type {
  border-radius: 4px;
  width: 100%;
  margin-bottom: 0;
}
.toggle input:hover + label {
  border-color: none;
  cursor: pointer;
}
.toggle input:checked + label {
  background-color: #fff;
  color: #000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: none;
  z-index: 0;
  font-weight: 700;
}
.toggle input:focus + label {
  outline: none;
}
