.section {
  margin-bottom: 32px;
}
.title {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 14pt;
  color: #1c1f26 !important;
  line-height: 32px;
  text-transform: uppercase;
}
.content {
  margin-top: 24px;
}
