body {
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important; */
  font-family: Roboto !important;
  padding: 0;
  margin: 0;
}
.layout-root-publicleasing {
  background-color: #f7f7f7;
  scrollbar-width: none;
}
h3 {
  font-family: "Montserrat";
}

.text-next-step {
  font-weight: 700;
  font-size: 32px;
  line-height: 38.4px;
}
.btn-next-step-wrapper {
  display: flex;
  margin-top: 20px;
}
.next-step-wrapper {
  margin-top: 60px;
  margin-bottom: 80px;
}

.z-index-1 {
  z-index: 1 !important;
}

.z-index-2 {
  z-index: 2 !important;
}

/* CSS FOR FIRE FOX */
@-moz-document url-prefix() {
  .body-wrapper {
    scrollbar-width: none !important;
  }

  *,
  ::before,
  ::after {
    scrollbar-width: none;
  }

  .car-holder-wrapper img {
    width: 100% !important;
  }
  @media only screen and (max-width: 767px) {
    .car-holder-wrapper img {
      width: 100% !important;
    }
  }
  @media only screen and (min-width: 1023px) and (max-width: 1366px) {
    .car-holder-wrapper img {
      width: 100% !important;
    }
  }
}

/* CSS FOR SAFARI */
@media not all and (min-resolution: 0.001dpcm) {
  .car-holder-wrapper img {
    width: 100% !important;
  }
  input.input-v2.focus {
    line-height: 120%;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
  }
  @media only screen and (max-width: 767px) {
    .car-holder-wrapper img {
      width: 100% !important;
    }
  }
  @media only screen and (min-width: 1023px) and (max-width: 1366px) {
    .car-holder-wrapper img {
      width: 100% !important;
    }
  }
}

.layout-wrapper {
  position: relative;
  height: 100vh;
}
.header-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  background-color: #fff;
}
.nom-eff-wrapper {
  font-size: 11px;
}
.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  scrollbar-width: none;
}
.scrollable-no-snapp {
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  scrollbar-width: none;
}
.body-wrapper {
  display: grid;
  max-width: 1366px;
  background-color: transparent;
  align-self: center;
  width: 100%;
  transition: all 0.3s ease-in-out;
  background-color: #f7f7f7;
}
.big-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 53px;
  color: #000000;
  margin-bottom: 32px;
}
.second-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 44px;
  color: #000000;
}

.body-left-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}
.right-holder {
  height: 20%;
}
::-webkit-scrollbar {
  display: none;
}

.block-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
  padding-top: 100px;
}

.cta-wrapper {
  position: fixed;
  width: 100%;
  bottom: 0;
}
.footer-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  background: #000000;
}
.linked-wrapper {
  position: relative;
  top: -8px;
}
.footer2 {
  display: flex;
  width: 100%;
  width: 100%;
  justify-content: center;
  background: #000000;
}

.toggle-switch-wrapper {
  width: 100%;
}
.select-model-wrapper {
  width: 100%;
  display: flex;
}
.right-content-holder {
  width: 100%;
}
.car-holder-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.car-holder-wrapper img {
  height: auto;
  width: 100%;
  transition: all 0.3s;
}
.bg-car-holder {
  display: flex;
  justify-content: center;
  align-items: center;
}
.bg-car-holder img {
  z-index: 1;
}
.car-holder-content {
  display: flex;
  z-index: 0;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0;
  height: 100%;
}
.button-direction-wrapper {
  display: flex;
  justify-content: center;
  margin: 80px 0 0 0;
}
.link-to-tesla {
  margin-top: 5px;
  color: #79a254;
  font-weight: 700;
}

.link-to-tesla:hover {
  color: #79a254;
  text-decoration: none;
}
.button-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
}

.logo {
  height: 28px;
}
.customer-type-wrapper {
  margin-bottom: 24px;
}

.right-disclaimer {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 130%;
  color: #999999;
  margin-top: 44px;
}
.right-disclaimer-mobile {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 130%;
  color: #999999;
}
.cta2 {
  display: flex;
  justify-content: center;
  z-index: 1;
}
.is-sticky {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 0px;
  padding-bottom: 0px;
}

/* very small device */
@media only screen and (max-width: 375px) {
  .big-title,
  .second-title {
    font-size: 30px !important;
    margin-bottom: 0 !important;
  }
  .body-wrapper {
    display: block;
    padding: 55px 28px 120px 28px !important;
  }
  .container-wrapper {
    display: block !important;
  }
  .col-sm-12 {
    padding-left: 0;
    padding-right: 0;
  }
  .btn-modal-wrapper {
    margin-bottom: 11px !important;
  }
  .for-short-info h2 {
    font-size: 30px;
    line-height: 36.4px;
  }
  .footer-wrap {
    display: flex !important;
    flex-direction: column;
  }
  .short-sub-title {
    font-size: 12px;
  }
  .big-title-short-info {
    font-size: 16px;
  }
  .nom-eff-wrapper {
    font-size: 11px;
  }
  .info-key {
    font-size: 12px;
  }
  .info-value {
    font-size: 12px;
  }
  .title-modal-cta-wrapper {
    margin-bottom: 10px;
  }
  .border-short {
    padding: 15px 15px;
    line-height: 11px;
  }
  .modal-sticky-wrap {
    padding: 15px 25px 25px 25px;
  }
  .short-info-wrapper {
    margin-bottom: 10px;
  }
  .ltq-config-modal {
    margin-top: 10px;
  }
  .right-disclaimer-mobile {
    margin-top: 5px !important;
  }
  .flow3-btn button {
    font-size: 14px;
  }
  .logo-and-link-wrapper {
    top: 50px;
  }
  .toggle {
    height: auto;
  }
  .toggle input + label {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
/* Small devices (tablets, 768px and smaller) */
@media only screen and (max-width: 767px) {
  .footer2 {
    display: block;
    padding: 15px 0px 0px 8px;
  }
  .block-content {
    height: auto;
  }
  .right-disclaimer {
    display: none;
  }
  .btn-next-step-wrapper {
    flex-direction: column;
  }
  .btn-next-step-wrapper button {
    width: 200px;
  }
  .linked-wrapper {
    left: 9px;
  }
  .line-horizontal-footer {
    margin-bottom: 32px;
    margin-top: 32px;
  }
  .footer-wrapper {
    position: relative;
    justify-content: start;
    padding-left: 2px;
    padding-top: 0;
    padding-bottom: 0;
  }
  img.logo-footer.img-fluid {
    padding-left: 14px;
    width: 226px !important;
  }
  .col-third {
    border-left: none;
    top: 0;
    height: unset;
    padding-left: 19px;
  }
  .main-info-footer {
    position: relative;
    top: -7px;
    border: none;
    padding-left: 8px;
  }
  .copyright-mobile-wrapper {
    font-size: 12px;
  }
  .footer-wrap {
    padding-top: 0;
    padding-bottom: 0;
    display: grid;
    grid-auto-columns: 350px !important;
  }

  .big-button-wrap {
    min-width: 31%;
  }
  .car-holder-wrapper img {
    width: 100% !important;
    padding: 0 !important;
  }
  .body-wrapper {
    padding: 55px 20px 120px 20px;
    background-color: transparent;
    grid-template-columns: none !important;
    height: auto !important;
  }
  .body-left-wrapper {
    align-items: unset !important;
    position: relative;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    height: auto !important;
    padding-top: 20% !important;
  }
  .car-holder-wrapper {
    position: relative;
    display: flex;
    height: auto !important;
    align-items: unset !important;
  }
  .block-content:nth-of-type(1) {
    height: auto !important;
    padding-top: 15% !important;
  }
  .big-title-wrapper {
    margin-bottom: 32px;
  }
  .big-title {
    margin-bottom: 0;
  }

  .big-title,
  .second-title {
    font-size: 32px;
    line-height: 120%;
  }
  .toggle {
    display: flex;
    justify-content: center;
  }
  .toggle input + label {
    font-size: 14px;
  }
  .col-second {
    margin-top: 40px;
  }

  .mb-sm-4 {
    margin-bottom: 24px !important;
  }
  .bg-car-holder {
    height: 146px;
  }

  .cta-wrapper {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  .modal-dialog {
    margin: 0;
  }
  .modal.fade .modal-dialog {
    height: 100%;
  }
  .modal-content {
    height: 100%;
  }
  .cta-main-info-wrapper {
    padding-left: 0 !important;
    margin-bottom: 20px;
  }
  .btn-modal-wrapper {
    display: flex;
    justify-content: start !important;
    margin-bottom: 20px;
  }
  .info-wrapper {
    display: flex;
    flex-direction: column-reverse;
  }
  .big-button-title {
    font-size: 16px !important;
  }
  .big-button-sub-title {
    font-size: 10px !important;
  }

  .cta-item-wrap {
    justify-content: space-evenly;
  }
  .toggle input:checked + label {
    z-index: 0;
  }

  .cta2 {
    width: 100%;
    z-index: 1;
  }
  .footer-wrap {
    grid-template-columns: none !important;
  }
  .big-button-wrap {
    max-width: 87px;
    padding: 1px;
  }
  .col-second-sticky-cta {
    padding-right: 20px;
  }
}

@media only screen and (max-height: 901px) {
  .big-title {
    font-size: 38px;
    line-height: 120%;
    margin-bottom: 32px;
  }
  .customer-type-wrapper {
    margin-bottom: 25px;
  }
  .footer-wrap {
    padding-top: 1%;
    padding-bottom: 1%;
  }
  .bg-car-holder {
    padding-top: 29px;
    padding-bottom: 29px;
  }
}

@media only screen and (min-width: 768px) {
  .right-disclaimer-mobile {
    display: none;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .ltq-config-modal {
    margin-top: 20px;
  }
  .button-wrapper {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 0px 6px;
    justify-content: unset !important;
  }
  .btn-next-step-wrapper button {
    font-size: 13px !important;
    min-width: unset !important;
  }
}

@media only screen and (min-width: 400px) and (max-width: 767px) {
  .body-left-wrapper {
    align-items: unset !important;
    padding-bottom: 7%;
  }
}

/* TABLET */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .button-wrapper {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 8px 8px;
  }
  .big-button-wrap {
    width: 100%;
    min-height: 83px;
    margin: unset;
  }

  .container {
    max-width: 100%;
  }
  .toggle input + label {
    font-size: 14px;
    line-height: 18.2px;
  }
  .big-title {
    line-height: 38.4px;
    margin-bottom: 32px;
  }
  .big-title,
  .second-title {
    font-size: 32px;
  }

  .big-button-title {
    font-size: 16px;
  }
  .big-button-sub-title {
    font-size: 12px;
  }
  .cta-item-wrap {
    display: none;
  }
  .car-holder-wrapper {
    top: 0;
  }
  .btn-modal-wrapper {
    justify-content: start !important;
    margin-bottom: 20px;
  }
  .header-right-item-wrapper {
    justify-content: end !important;
    padding-right: 1em;
  }
  .flow3-btn {
    flex-direction: column;
    min-width: 200px !important;
  }
  .flow3-btn button {
    min-width: 200px !important;
  }
}

/*  small desktop */
@media only screen and (min-width: 992px) and (max-width: 1192px) {
  .button-wrapper {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 8px 8px;
  }
  .mb-3.flow3-btn button {
    min-width: 100px;
    font-size: 14px;
  }
  .big-title {
    font-size: 35px;
    line-height: 38.4px;
    margin-bottom: 20px;
  }
  .customer-type-wrapper {
    margin-bottom: 12px;
  }
  .mb-sm-4 {
    margin-bottom: 0px !important;
  }
  .footer-wrap {
    padding-top: 5px;
    padding-bottom: 15px;
  }
}

/* @media only screen and (min-width: 1365px) {
  .car-holder-wrapper img {
    width: 600px;
  }
} */

/* GRID SYSTEM */

.item-wrapper {
  max-width: 1366px;
}
.container-wrapper {
  display: flex;
  justify-content: center;
  min-height: 400px;
}
.h-screen {
  height: 100vh;
}
