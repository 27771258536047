.button {
  width: 84px;
  height: 55px;
  color: #bdbdbd;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #afca0b;
}

.w-62 {
  width: 62px !important;
}

.s-10 {
  font-size: 10px !important;
}

.selected {
  background-color: #79a254 !important;
  color: white;
}
.selected > * {
  color: white !important;
}
.ic-label {
  color: #333333;
}
.typo {
  overflow-wrap: anywhere;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 22.4px;
  text-align: center;
}
.text {
  color: #1c1f26;
}

.input-group-text,
.form-control,
.btn-light:focus,
.btn-light.focus, .btn-light:hover, .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  border-color: #afca0b !important;
}

.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem #afca0b !important;
}

.react-datepicker {
  border: 1px solid #afca0b;
}