input {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.w-180 {
  width: 180px !important;
}
.list-group {
  border: 1px solid #dfdfdf;
  position: absolute;
  top: 60px;
  left: 14px;
  right: 14px;
  z-index: 1;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
.list-group-item:hover,
.list-group-item:focus,
.list-group-item:active {
  color: #fff;
  background-color: #79a254 !important;
}
.list-group-item {
  border: 1px solid #dfdfdf;
  border-left: none;
  border-right: none;
}
.list-group-item:first-child {
  border-top-width: 0;
}
.list-group-item:last-child {
  border-bottom-width: 0;
}
.spinner {
  position: absolute;
  top: 15px;
  right: 20px;
}
.spinner .text-success {
  color: #79a254 !important;
  width: 1.5rem;
  height: 1.5rem;
  border: 0.2em solid currentColor;
  border-right-color: transparent;
}
