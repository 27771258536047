.big-button-wrap {
  background-color: rgb(225, 225, 225);
  border-radius: 8px;
  border: none;
  text-align: center;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 32%;
  height: 83px;
  margin: 0;
  color: #000;
}
.big-button-content-wrapper {
  padding: 16px 0;
}
.big-button-wrap.idle {
  background-color: rgb(225, 225, 225);
  border-radius: 8px;
  border: none;
  text-align: center;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.big-button-wrap.disabled {
  background-color: rgb(242, 242, 242);
  color: rgb(179, 179, 179);
  border-radius: 8px;
  border: none;
  text-align: center;
  /* padding: 32px 16px; */
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.big-button-wrap.disabled:hover {
  background-color: rgb(242, 242, 242);
  color: rgb(179, 179, 179);
  cursor: not-allowed;
}
.big-button-wrap:hover {
  background-color: #cccccc;
  color: #000;
}
.big-button-wrap:active {
  background-color: #000000;
  color: #fff;
}
.big-button-wrap.active {
  background-color: #000000;
  color: #fff;
}
.big-button-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 20.8px;
  margin-top: 8px;
  margin-bottom: 12px;
}
.big-button-sub-title {
  font-size: 11px;
  font-weight: 400;
  line-height: 14.3px;
}
/* primary button */
.btn-wrap.primary {
  background-color: #689850;
  color: #fff;
  border-color: transparent;
  padding: 10px;
  border-radius: 6px;
  box-shadow: none;
  cursor: pointer;
  min-width: 170px;
  transition: all 0.3s;
}
.btn-wrap.primary:hover {
  background-color: #8daa76;
}
.btn-wrap.primary.disabled {
  background-color: #c5c5c5;
  color: #fff;
  cursor: not-allowed;
}
/* primary button */
.btn-wrap.secondary {
  background-color: #f7f9ea;
  color: #689850;
  border: 0.5px solid #689850;
  padding: 11px;
  border-radius: 6px;
  box-shadow: none;
  cursor: pointer;
  min-width: 100px;
  transition: all 0.3s;
}
/* secondary button */
.btn-wrap.secondary {
  background-color: transparent;
  color: #689850;
}
.btn-wrap.secondary:hover {
  background-color: #f7f9ea;
  color: #689850;
}
.btn-wrap.secondary.disabled {
  background-color: transparent;
  border-color: #c5c5c5;
  color: #c5c5c5;
  cursor: not-allowed;
}
