.img-wrapper {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-right: 5px
}

.image {
    max-width: 100%;
    max-height: 100%;
    display: block
}