.calculator-container {
  background-color: white !important;
  position: relative;
}

.calculatorScreen {
  background-color: white !important;
  color: #1C1F26 !important;
}

.imageCar {
  height: 100%;
  width: 100%;
  /* object-fit: contain; */
}

.car-wrapper {
  display: flex;
  background-color: white;
}

.custom-title-layout {
  font-weight: 100;
  font-style: normal;
  font-size: 30pt;
  color: #ffffff;
}

.custom-calculator-input {
  background-color: white !important;
  color: #333333 !important;
  height: 2em;
}

.calc-label {
  color: #1C1F26 !important;
}

.custom-calculator-button {
  background-color: #ffffff;
  color: #1C1F26;
  font-size: x-large;
}

.calculator-title {
  font-style: normal;
  font-weight: 400;
  font-size: 26pt;
  letter-spacing: 0px;
  text-align: left;
  color: #007bff;
}

.infoIcon {
  height: 1.5em;
  width: 1.5em;
  border-radius: 1em;
  background-color: lightgrey;
  border: 1px solid lightgrey;
}

.interest-label {
  font-weight: 500;
  font-size: larger;
}

.custom-number-input {
  text-align: right;
}

.form-control-lg {
  font-size: 1.5rem;
}

.final-value-input {
  height: 2em;
}

.bg-green, .bg-green:disabled {
  -webkit-text-fill-color: white;
  color: white !important;
  text-align: right;
  height: 2em;
  background-color: #79A254 !important;
  border: 1px solid #79A254 !important;
  box-shadow: 1px 1px 1px 1px #79A254;
}

.font-small {
  font-size: 18pt !important;
}

.logo-icon-wrapper {
  width: 28px;
  height: 28px;
}

.custom-color-input {
  border: none;
  position: relative;
  height: 2em;
  font-weight: 500;
  color: #1c1f26 !important;
  background-color: #fff !important;
}

.custom-color-input::before {
  content: " ";
  border-left: 2px solid;
  border-bottom: 2px solid;
  border-color: #79A254;
  height: 100%;
  position: absolute;
  width: 95%;
  left: 0;
  top: 0;
}

.custom-color-input::after {
  content: " ";
  border-right: 2px solid;
  border-top: 2px solid;
  border-color: #AFCA0B;
  height: 100%;
  position: absolute;
  width: 95%;
  right: 0;
  top: 0;
}

.page-header {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 14pt;
  color: #1C1F26 !important;
  text-transform: uppercase;
}