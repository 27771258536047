.menu {
    border-top: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
  }
  .menu div{
    margin: auto;
  }
  .layout-header {
    background-color: white;

  }
  .layout-header img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  .step {
    font-style: normal;
    font-weight: 550;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: #00CCFF;
  }

  .menu > .container {
    padding: 0;
    overflow-x: auto
  }