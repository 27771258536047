.sub-title-v2 {
  font-weight: 700;
  font-size: 14px;
  line-height: 130%;
}

.title-v2 {
  font-weight: 700;
  font-size: 14px;
  line-height: 130%;
}

/* input.input-v2:focus {
  border: none;
  color: #000;
  background-color: #79a254;
  height: 37px;
} */
/* input.input-v2:hover {
  color: #000;
  background-color: transparent;
} */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
input.input-v2.focus {
  border: none;
  height: 37px;
  background-color: transparent;
}
input.input-v2::selection {
  background-color: #79a254;
  color: #000 !important;
}
input.input-v2.clicked {
  color: #000;
  background-color: transparent;
}

.desc-v2 {
  font-size: 11px;
  line-height: 130%;
  font-weight: 400;
  color: #808080;
}
.line-edit {
  height: 37px;
  margin-left: 10px;
  border-left: 1px solid #000;
}
.bg-image-none {
  background-image: none !important ;
}
input.input-v2 {
  height: 37px;
  border: none;
  font-size: 32px;
  transition: 0.5s;
  outline: none;
  color: #79a254;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
}

.big-number {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
  /* or 38px */
  letter-spacing: 0.1px;
  /* Leaseteq Dark Green/100 */
  color: #79a254;
  margin-bottom: 0;
  height: 37px;
  cursor: pointer;
}
.icon-edit-wrap {
  cursor: pointer;
}
