.textarea {
  resize: none;
  height: 150px !important;
}
.number-input-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;
}
.number-input-wrapper .label {
  margin-right: 15px;
}
.number-input input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
.number-input input[type="number"]::-webkit-inner-spin-button,
.number-input input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.number-input button {
  -webkit-appearance: none;
  background-color: transparent;
  height: 100%;
  border: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0;
  position: relative;
}
.number-input button:before,
.number-input button:after {
  display: inline-block;
  position: absolute;
  content: "";
  height: 2px;
  transform: translate(-50%, -50%);
}
.number-input button.plus:after {
  transform: translate(-50%, -50%) rotate(90deg);
}
.number-input input[type="number"] {
  text-align: center;
}
.quantity{
  border: 1px solid #AFCA0B;
}
.quantity:focus-visible, .quantity:focus, .quantity:focus-within{
  outline-color: #AFCA0B !important;
}
.number-input {
  border: 1px solid #AFCA0B;
  width: 10rem;
  border-radius: 0.25rem;
}
.number-input button {
  width: 2.6rem;
  height: 0.7rem;
}
.number-input button.minus {
  padding-left: 10px;
}
.number-input button:before,
.number-input button:after {
  width: 0.7rem;
  background-color: #495057;
}
.number-input input[type="number"] {
  max-width: 4rem;
  padding: 0.5rem;
  border: 1px solid #AFCA0B;
  border-width: 0 1px;
  font-size: 1rem;
  height: 2rem;
  color: #495057;
}
