@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 1200px !important;
  }
}

@media (min-width: 3840px) {
  .modal-lg, .modal-xl {
    max-width: 1800px !important;
  }
}

.verificationContainer {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: auto;
}

.min-height-0 {
  min-height: 0;
}

.lableText1 {
  font-style: normal;
  font-weight: 550;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #00ccff;
}

.lableTitle {
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #2c2c2c;
  text-transform: uppercase;
}

.imageContainer {
  flex-grow: 1;
  height: 100%;
}

.verificationImage {
  height: 15em;
  border: 1px solid #828282;
}

.rowText {
  border-bottom: 1px solid #e0e0e0;
}

.imageText {
  color: #828282;
}

.pdfView {
  width: 100%;
}

.footerIcon {
  height: 1.5em;
  width: 1.5em;
  border-radius: 1em;
  background-color: #828282;
}

.footerInfoText {
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
}



/* New CSS */

.verification-title {
  font-weight: 400;
  font-style: normal;
  font-size: 26pt;
  color: #007bff !important;
}

.verification-image-wrapper {
  max-height: 20em;
}

.verification-image-car {
  height: 100%;
  width: 100%;
  /* object-fit: contain; */
}

.verification-label-model {
  font-size: 2.2rem;
  font-weight: 500;
}

.confirmation-button, .confirmation-button:hover, .confirmation-button:active, .confirmation-button:focus {
  font-family: "Montserrat";
  border-radius: 8px;
  color: white !important;
  height: 2em;
  background-color: #79A254 !important;
  border: 1px solid #79A254 !important;
  box-shadow: 1px 1px 1px 1px #79A254;
  font-size: 1.5rem;
}

.verification-information-box {
  background-color: #fff;
  padding: 2rem;
  border: 1px solid black;
}

.h-64 {
  height: 64px !important;
}

.pr-65 {
  padding-right: 65px !important;
}

.text-row {
  font-size: 20px;
}